import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ProjectPage from 'components/ProjectPage'

const Project = ({ data }) => (
  <>
    <Helmet title='TD Banking App | François Deguire' />
    <ProjectPage
      name='TD Banking'
      description={`
       This fictional redesign of the TD Mobile app aims to simplify 
       the customer’s on-the-go banking experience. By using a card system, 
       recent expenses and investment performance are shown right underneath 
       account cards on the home screen. This allows the user to passively 
       gather information about his accounts without requiring clickthrough. 
       A unified support chat UI using the Clari bot was also developed to 
       regroup the different ways of getting help with your TD accounts.
      `}
      meta={`
        Personal project<br>
        In 2020
      `}
      images={Object.values(data).map(v => v.childImageSharp.fluid)}
    />
  </>
)

export const query = graphql`
  query {
    tdbank1: file(relativePath: { eq: "tdbank/1.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tdbank2: file(relativePath: { eq: "tdbank/2.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tdbank3: file(relativePath: { eq: "tdbank/3.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tdbank4: file(relativePath: { eq: "tdbank/4.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tdbank5: file(relativePath: { eq: "tdbank/5.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Project
